import { initFlowbite } from "flowbite";
import React, { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import Documents from "../../components/Documents";
import DropdownFilter from "../../components/Dropdowns/DropdownFilter";
import Sidebar from "../../components/Sidebar";
import GlobalContext from "../../store/context";
import { filter, paginate } from "../../utils/document/Filter";
import mapping from "../../utils/mapping";

import Queries from "../../utils/queries";

const Publications = () => {
  const [selectedSubfolderId, setSelectedSubfolderId] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState("Tous");
  const [displayCount, setDisplayCount] = useState(
    process.env.REACT_APP_DISPLAY_COUNT_DOCS
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [documents, setDocuments] = useState([]);
  const folders = useLoaderData();
  const { isMobileSidebarOpen } = useContext(GlobalContext);

  useEffect(() => {
    initFlowbite();
  }, []);

  useEffect(() => {
    setSelectedFormat("Tous");
    setDisplayCount(process.env.REACT_APP_DISPLAY_COUNT_DOCS);
    setCurrentPage(1);
  }, [selectedSubfolderId]);

  const { paginatedDocuments, totalFiltered, totalPages } = paginate(
    documents,
    currentPage,
    displayCount
  );

  const applyFilters = (documents) => {
    return filter(documents, selectedFormat);
  };

  const filteredDocuments = applyFilters(paginatedDocuments);

  const onSelectedSubfolderIdHandler = (selectedSubfolderId) => {
    setSelectedSubfolderId(selectedSubfolderId);
  };

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const onChangeDisplayCount = (event) => {
    const newDisplayCount = parseInt(event.target.value);
    if (newDisplayCount <= 0) {
      // we can display an error message here or prevent modification
      return;
    }
    setDisplayCount(newDisplayCount);
    const newTotalPages = Math.ceil(totalFiltered / newDisplayCount);
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }
  };

  const onChangeCurrentPage = (event) => {
    const newPage = parseInt(event.target.value);
    if (newPage <= 0 || newPage > totalPages) {
      //we can display an error message here or prevent modification
      return;
    }
    setCurrentPage(newPage);
  };

  const onFetchedDocumentsHandler = (documents) => {
    setDocuments(documents);
  };

  return (
    <>
      {/* <div className="bg-opacity-10 h-screen w-screen bg-gradient-to-r from-green-50 to-blue-50"> */}
      <div
        className="opacity-100 h-screen bg-fixed bg-cover bg-no-scroll bg-no-repeat bg-right  mt-[70px]"
        style={{ backgroundImage: "url('/images/bg/3.jpg')" }}
      >
        <div className="flex">
          <div className="h-auto md:w-[28rem] lg:w-[22rem] xl:w-[22rem]">
            <Sidebar
              folders={folders}
              onSelectedSubfolderId={onSelectedSubfolderIdHandler}
            />
          </div>

          <div
            className={`${
              isMobileSidebarOpen ? "flex-initial" : ""
            } h-auto w-full`}
          >
            <div className="flex flex-col flex-1 lg:flex-row items-center justify-between bg-gradient-to-b from-white via-gray-50 to-gray-50 dark:bg-gray-700 p-4 mx-auto w-full mb-5">
              <div className="lg:ml-2 mb-2 lg:mb-0">
                <p className="flex flex-col lg:flex-row items-center">
                  <span className="p-2 text-sm font-custom">Affichage</span>
                  <input
                    type="number"
                    className="w-16 h-5 border border-gray-300 rounded"
                    min={1}
                    max={process.env.REACT_APP_MAX_DISPLAY_DOCS}
                    value={displayCount}
                    onChange={onChangeDisplayCount}
                  />
                  <span className="p-2 text-sm font-custom">Élément(s)</span>
                </p>
              </div>
              <div className="lg:ml-4 mb-2 lg:mb-0">
                <p className="flex flex-col lg:flex-row items-center">
                  <span className="p-2 text-sm font-custom">Page</span>
                  <input
                    type="number"
                    className="w-16 h-5 border border-gray-300 rounded"
                    min={1}
                    max={totalPages}
                    value={currentPage}
                    onChange={onChangeCurrentPage}
                  />
                  <span className="p-1">/</span>
                  <span className="p-2 text-sm font-custom">
                    {totalPages} page(s)
                  </span>
                </p>
              </div>
              <DropdownFilter
                options={["Tous", "Vidéo", "Audio", "Image", "Texte"]}
                subfolderId={selectedSubfolderId}
                onChange={handleFormatChange}
                selected={selectedFormat}
              />
              <p className="p-2 text-sm font-custom">
                {filteredDocuments.length} document(s) affiché(s)
              </p>
            </div>
            {/* <hr className="h-px my-4 bg-gray-100 border-0 dark:bg-gray-700"></hr> */}
            <Documents
              subfolderId={selectedSubfolderId}
              onFetchedDocuments={onFetchedDocumentsHandler}
              filteredDocuments={filteredDocuments}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Publications;

export async function loader() {
  try {
    const response = await Queries.folders(process.env.REACT_APP_FOLDER_ID);
    const folders = mapping.folder(response.data);
    return folders;
  } catch (error) {
    throw error;
    // Gérer l'erreur de manière appropriée, par exemple :
    // setError("Impossible de charger les documents récents. Veuillez réessayer plus tard.");
  } finally {
  }
}
