import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import ErrorPage from "./pages/Errors";
import Home from "./pages/Home";
import RootLayout from "./pages/Layouts/Root";
import Publications, { loader as folderLoader } from "./pages/Publications";
import SearchResults, { loader as searchLoader } from "./pages/SearchResults";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Home />,
            // loader: recentsDocsLoader,
          },
          {
            path: "publications",
            element: <Publications />,
            loader: folderLoader,
          },
          {
            path: "search/find/",
            element: <SearchResults />,
            loader: searchLoader,
            // action: searchAction,
          },
          // {
          //   path: 'preview/:docId',
          //   element: <DocumentViewer />,
          //   // loader: contentLoader,
          // },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
