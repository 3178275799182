import { useQuery } from "@tanstack/react-query";
import { fetchFoldersWithSubfolders } from "../utils/queries/utils";

export default function useFoldersWithSubfolders(folders) {
  return useQuery({
    queryKey: ["foldersWithSubfolders", folders],
    queryFn: () => fetchFoldersWithSubfolders(folders),
    enabled: !!folders,
  });
}
