import React, { useState } from "react";
import thumbnail from "../../utils/document/Thumbnail";
import { objectURL } from "../../utils/document/ObjectURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faInfoCircle,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatedAuthorsName,
  truncateText,
} from "../../utils/document/FormatedAuthorsName";
import { getFileNameWithoutExtension } from "../../utils/document/GetFileNameWithoutExtension";
import { Spinner } from "@material-tailwind/react";
import { getBinaryContent } from "../../utils/document/GetBinaryContent";
import HandlePreview from "../../utils/document/HandlePreview";
import ModalPreview from "../Modals";
import PDFModal from "../Modals/PDFModal";
import { clsx } from "clsx";

const viewableFileTypeIcon = ["audio", "video", "file", "pdf", "txt", "image"];

function Document({ filteredDocuments, onIsDetailsView, onDetailsViewed }) {
  const [showDialog, setShowDialog] = useState(false);
  const [docPreview, setDocPreview] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [loadingPreviews, setLoadingPreviews] = useState({});
  const [getBinaryContentError, setGetBinaryContentError] = useState(null);

  const generateURL = async (document) => {
    try {
      setLoadingPreviews((prevState) => ({
        ...prevState,
        [document.id]: true,
      }));
      const binaryContent = await getBinaryContent(document);
      const url = await objectURL(document, binaryContent);

      setPreviewURL(url);
      setDocPreview(document);
      setShowDialog(true);
      setLoadingPreviews((prevState) => ({
        ...prevState,
        [document.id]: false,
      }));
    } catch (error) {
      setLoadingPreviews((prevState) => ({
        ...prevState,
        [document.id]: false,
      }));
      // console.error("Une erreur s'est produite :", error);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    onDetailsViewed(null);
    //initialisation docPreview and preview url for avoid bugs
    URL.revokeObjectURL(previewURL);
    setPreviewURL(null);
    setDocPreview(null);
  };

  const handleDetailsView = (document) => {
    onIsDetailsView(true);
    onDetailsViewed(document);
  };

  const downloadBlob = (blobData, fileName) => {
    const blob = new Blob([blobData]);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleDownload = async (doc) => {
    try {
      const binaryContent = await getBinaryContent(doc);
      downloadBlob(binaryContent, doc.fileName);
    } catch (error) {
      console.error("Erreur lors du téléchargement du document :", error);
    }
  };

  return (
    <>
      {filteredDocuments.map((document) => (
        <div key={document.id} className="">
          <div className="bg-gray-50 border border-slate-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 drop-shadow-lg hover:drop-shadow-2xl h-60">
            <div className="flex justify-center pt-2 mt-0.5 mx-1 h-24 bg-gray-200 rounded-lg">
              {thumbnail(document.fileName).composant}
            </div>

            <div className="flex-none line-clamp-2 h-16 pt-2 px-2.5 text-sm font-bold  text-gray-900 dark:text-white break-words tracking-tight items-center   md:line-clamp-3 ">
              {getFileNameWithoutExtension(document.fileName)}
            </div>

            {document.attributes.length > 0 &&
              document.attributes.map((attribute, index) => {
                if (attribute.name === "authors") {
                  return (
                    <p
                      key={index}
                      className="mb-2 px-2.5 h-15 pt-2 md:pt-0 font-normal text-gray-700 dark:text-gray-400
                            text-xs font-custom text-wrap  md:text-base break-words line-clamp-1"
                    >
                      <span>
                        Auteurs:{" "}
                        {truncateText(
                          attribute?.stringValue
                            ? formatedAuthorsName(attribute?.stringValue)
                            : formatedAuthorsName(attribute?.value),
                          40
                        )}
                      </span>

                      <br />
                    </p>
                  );
                }
                return null;
              })}
            <hr className="bg-green-200 h-0.5 mx-2" />
            <div className="absolute px-2 mb-2 mt-0.5 mx-1 rounded-lg inset-x-0 bottom-0 center flex justify-around h-18">
              {viewableFileTypeIcon.includes(
                thumbnail(document.fileName).type
              ) &&
                (thumbnail(document.fileName).type === "audio" ||
                thumbnail(document.fileName).type === "video" ? (
                  <span className="mx-2 cursor-pointer">
                    {loadingPreviews[document.id] ? (
                      <Spinner className="h-4 w-4" />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlay}
                        size="lg"
                        style={{ color: "#b03a2e" }}
                        onClick={() => generateURL(document)}
                      />
                    )}
                  </span>
                ) : (
                  <span className="mx-2 cursor-pointer">
                    {loadingPreviews[document.id] ? (
                      <Spinner className="h-4 w-4" />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEye}
                        size="lg"
                        style={{ color: "#007BFF" }}
                        onClick={() => generateURL(document)}
                      />
                    )}
                  </span>
                ))}
              <FontAwesomeIcon
                icon={faDownload}
                size="lg"
                className="mx-2 cursor-pointer"
                style={{ color: "#28a745" }}
                onClick={() => handleDownload(document)}
              />
              {document.attributes.length > 0 &&
                document.attributes.map((attribute, index) => {
                  if (attribute.name === "descriptionP") {
                    return (
                      <span key={index}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="lg"
                          className="mx-2 cursor-pointer"
                          style={{ color: "#17a2b8" }}
                          onClick={() => handleDetailsView(document)}
                        />
                      </span>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      ))}
      {docPreview !== null &&
        (docPreview.fileName.split(".").pop().toLowerCase() === "pdf" ? (
          <PDFModal isVisible={showDialog} onClose={handleCloseDialog}>
            <HandlePreview previewURL={previewURL} docPreview={docPreview} />
          </PDFModal>
        ) : (
          <ModalPreview isVisible={showDialog} onClose={handleCloseDialog}>
            <HandlePreview previewURL={previewURL} docPreview={docPreview} />
          </ModalPreview>
        ))}
    </>
  );
}

export default Document;
