export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export const formatedAuthorsName = (names) => {
  if (typeof names === "string" && names.trim() !== "") {
    const namesArray = names.split(",");
    if (namesArray.length > 0) {
      const formattedNames = namesArray.map((name) => name.trim());
      return formattedNames.join(",");
    }
  }
  // Retourne un message par défaut ou une valeur vide si `names` n'est pas défini ou n'est pas une chaîne
  return names || "Aucun auteur";
};
