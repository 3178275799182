import _ from "lodash";

const Mapping = {
  folder(folders) {
    const mappedFolders = _.map(folders, (folder) => ({
      id: folder.id,
      name: folder.name,
      parentId: folder.parentId,
    }));

    return mappedFolders;
  },

  document(documents) {
    const mappedDocuments = _.map(documents, (document) => ({
      id: document.id,
      fileName: document.fileName,
      publisher: document.publisher,
      creation: document.creation,
      creator: document.creator,
      attributes: document.attributes,
      folderId: document.folderId,
    }));

    return mappedDocuments;
  },
};

export default Mapping;
