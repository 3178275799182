import { Outlet, useLocation, useNavigation } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";

const RootLayout = () => {
  const navigation = useNavigation();
  const location = useLocation();

  return (
    <div>
      <Header />
      {navigation.state === "loading" ? <Spinner /> : <Outlet />}

      {location.pathname &&
        location.pathname.indexOf("publications") === -1 && <Footer />}
    </div>
  );
};

export default RootLayout;
