import React, { useEffect, useState } from "react";

const ImprovedSpinner = ({
  text = "Chargement des dernières publications...",
}) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? "" : prev + "."));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center py-8 space-y-4">
      {/* <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin"></div> */}
      <p className="text-lg text-gray-600 font-medium text-center animate-pulse">
        {text}
        {dots}
      </p>
    </div>
  );
};

export default ImprovedSpinner;
