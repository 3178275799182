import Queries from ".";
import mapping from "../mapping";

export const compareDocumentDates = (a, b) =>
  new Date(b.creation).getTime() - new Date(a.creation).getTime();

export async function fetchDocumentsRecursively(
  rootFolderId,
  maxResults,
  processedFolders = new Set(),
  currentBestDocs = []
) {
  if (processedFolders.has(rootFolderId)) {
    return currentBestDocs;
  }
  processedFolders.add(rootFolderId);

  try {
    // First, we retrieve all subfolders
    const foldersResponse = await Queries.folders(rootFolderId);

    // For the root folder, we don't take its direct documents
    let currentFolderDocs = [];
    if (rootFolderId !== process.env.REACT_APP_FOLDER_ID) {
      const docsResponse = await Queries.documents(rootFolderId);
      currentFolderDocs = docsResponse.data;
    }

    // Recursively retrieve documents from subfolders
    const subFolderResults = await Promise.all(
      foldersResponse.data
        .filter((folder) => !processedFolders.has(folder.id))
        .map((folder) =>
          fetchDocumentsRecursively(
            folder.id,
            maxResults,
            processedFolders,
            currentBestDocs
          )
        )
    );

    // We combine all documents
    let allDocs = [
      ...currentBestDocs,
      ...currentFolderDocs,
      ...subFolderResults.flat(),
    ];

    // Sort and limit the number of results
    return allDocs.sort(compareDocumentDates).slice(0, maxResults);
  } catch (error) {
    console.error(
      `Erreur de récupération pour le dossier ${rootFolderId}:`,
      error
    );
    return currentBestDocs;
  }
}

export async function enrichDocumentWithCategory(doc) {
  try {
    const categoryResult = await Queries.getDocumentsCategory(doc.id);
    return {
      ...doc,
      category: categoryResult.data.name,
    };
  } catch (categoryError) {
    console.warn(
      `Impossible de récupérer la catégorie pour le document ${doc.id}`,
      categoryError
    );
    return {
      ...doc,
      category: "",
    };
  }
}

export async function enrichDocumentsWithCategories(documents) {
  if (!documents) return [];
  return Promise.all(documents.map(enrichDocumentWithCategory));
}

export async function fetchSubfolders(folderId) {
  try {
    const subfolderResponse = await Queries.folders(folderId);
    return mapping.folder(subfolderResponse.data);
  } catch (error) {
    //console.log("ERROR ", error);
    throw error;
  }
}

export async function fetchFoldersWithSubfolders(folders) {
  if (!folders) return [];

  const results = await Promise.all(
    folders.map(async (folder) => {
      const subfolderData = await fetchSubfolders(folder.id);
      return {
        folder,
        subfolders: subfolderData,
      };
    })
  );

  return results;
}
