export const formatDate = (dateString) => {
  let date = new Date(dateString);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  const formattedDate = `${day}-${month}-${date.getFullYear()}`;

  return formattedDate;
};

export const granularFormatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  // Set thresholds for different durations
  const thresholds = {
    second: 60,
    minute: 3600,
    hour: 86400,
    day: 604800,
    week: 2592000,
    month: 31536000,
  };

  if (diffInSeconds < thresholds.second) {
    return "à l'instant";
  } else if (diffInSeconds < thresholds.minute) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `il y a ${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else if (diffInSeconds < thresholds.hour) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `il y a ${hours} heure${hours > 1 ? "s" : ""}`;
  } else if (diffInSeconds < thresholds.day) {
    const days = Math.floor(diffInSeconds / 86400);
    return `il y a ${days} jour${days > 1 ? "s" : ""}`;
  } else if (diffInSeconds < thresholds.week) {
    const weeks = Math.floor(diffInSeconds / 604800);
    return `il y a ${weeks} semaine${weeks > 1 ? "s" : ""}`;
  } else if (diffInSeconds < thresholds.month) {
    const months = Math.floor(diffInSeconds / 2592000);
    return `il y a ${months} mois`;
  } else {
    const years = Math.floor(diffInSeconds / 31536000);
    return `il y a ${years} an${years > 1 ? "s" : ""}`;
  }
};
