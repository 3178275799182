import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faPlay,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { objectURL } from "../../../utils/document/ObjectURL";
import { truncateText } from "../../../utils/document/FormatedAuthorsName";
import { formatedAuthorsName } from "../../../utils/document/FormatedAuthorsName";
import { getBinaryContent } from "../../../utils/document/GetBinaryContent";
import thumbnail from "../../../utils/document/Thumbnail";
import { granularFormatDate } from "../../../utils/document/FormatedDate";
import HandlePreview from "../../../utils/document/HandlePreview";
import ModalPreview from "../../Modals";
import PDFModal from "../../Modals/PDFModal";
import { Spinner } from "@material-tailwind/react";

function Recent({ documents, onDetailsView }) {
  const [showDialog, setShowDialog] = useState(false);
  const [docPreview, setDocPreview] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [loadingPreviews, setLoadingPreviews] = useState({});
  const viewableFileTypeIcon = [
    "audio",
    "video",
    "file",
    "pdf",
    "txt",
    "image",
  ];
  async function generateURL(document) {
    try {
      setLoadingPreviews((prevState) => ({
        ...prevState,
        [document.id]: true,
      }));
      const binaryContent = await getBinaryContent(document);
      const url = await objectURL(document, binaryContent);

      setPreviewURL(url);
      setDocPreview(document);
      setShowDialog(true);
      setLoadingPreviews((prevState) => ({
        ...prevState,
        [document.id]: false,
      }));
    } catch (error) {
      setLoadingPreviews((prevState) => ({
        ...prevState,
        [document.id]: false,
      }));
      //console.error("Une erreur s'est produite :", error);
    }
  }

  const handleCloseDialog = () => {
    setShowDialog(false);
    onDetailsView(null);
    //initialisation docPreview and preview url for avoid bugs
    URL.revokeObjectURL(previewURL);
    setPreviewURL(null);
    setDocPreview(null);
  };

  const getFileNameWithoutExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, lastDotIndex);
    return nameWithoutExtension;
  };

  const downloadBlob = (blobData, fileName) => {
    const blob = new Blob([blobData]);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleDownload = async (doc) => {
    try {
      const binaryContent = await getBinaryContent(doc);
      downloadBlob(binaryContent, doc.fileName);
    } catch (error) {
      console.error("Erreur lors du téléchargement du document :", error);
    }
  };

  return (
    <>
      <div className=" bg-gray-100 mt-10 md:mt-8 w-full">
        <div
          className="w-full pt-1 h-12 mb-2 md:mb-8 uppercase tracking-wide text-base md:text-md md:text-base lg:text-xl xl:text-2xl 2xl:text-3xl 
          text-center font-semibold antialiased font-custom"
        >
          Dernières Publications
        </div>
        <div className="w-11/12  mx-auto ">
          <div className="grid grid-cols-1 mx-10 md:grid-cols-2 md:mx-0 gap-x-6 gap-y-4 lg:grid-cols-4 lg:mx-0">
            {documents.length > 0 &&
              documents.map((document) => (
                <div key={document.id}>
                  <div
                    className="rounded-xl transition hover:shadow-lg shadow-gray-600 relative"
                    // onClick={() => generateURL(document)}
                  >
                    <div
                      className=" flex flex-col space-y-2 lg:space-y-6 md:space-y-1 relative max-w-full p-3 pt-0 h-56 md:h-52 lg:h-60 bg-white border border-gray-200  shadow  dark:bg-gray-800
                           dark:border-gray-100 "
                    >
                      <div className="h-7 lg:h-9 mt-3  ">
                        <span className="text-white p-1 md:p-2 bg-pink-800 text-xs h-7 font-light uppercase md:text-base">
                          {document.category}
                        </span>
                      </div>
                      <div className="mt-9 md:mt-2 m-1 h-9 md:h-11 lg:h-11">
                        <h5 className=" text-xs line-clamp-2  md:line-clamp-2 font-bold tracking-tight text-gray-900 dark:text-white md:text-base">
                          {getFileNameWithoutExtension(document.fileName)}
                        </h5>
                      </div>
                      <div className="h-14 mb-2 ">
                        {document.attributes.length > 0 &&
                          document.attributes.map((attribute, index) => {
                            if (attribute.name === "descriptionP") {
                              return (
                                <p
                                  key={`${attribute.name}-${index}`}
                                  className="text-xs pl-1 line-clamp-2  md:text-sm tracking-tight text-gray-700 dark:text-gray-400 lg: text-gray-500  font-semibold "
                                >
                                  {attribute.stringValue}
                                </p>
                              );
                            }
                            return null;
                          })}

                        {document.attributes.length > 0 &&
                          document.attributes.map((attribute, index) => {
                            if (attribute.name === "authors") {
                              return (
                                <p
                                  key={index}
                                  className="mb-2 pl-1 pt-2 md:pt-0 italic md:not-italic font-medium text-black dark:text-gray-400
                                      text-xs line-clamp-2 md:text-sm md:line-clamp-1 tracking-tight"
                                >
                                  <span>
                                    Auteurs:{" "}
                                    {truncateText(
                                      attribute?.stringValue
                                        ? formatedAuthorsName(
                                            attribute?.stringValue
                                          )
                                        : formatedAuthorsName(attribute?.value),
                                      100
                                    )}
                                  </span>

                                  <br />
                                </p>
                              );
                            }
                            return null;
                          })}
                      </div>
                      <div className="mt-3 md:mt-2 lg:mt-1">
                        <span className="py-2 h-8 absolute bottom-1.5  md:bottom-3 left-2 text-xs text-gray-600 md:text-black md:text-sm italic tracking-tight ">
                          <FontAwesomeIcon
                            icon={faClock}
                            size="sm"
                            style={{ color: "#4d5656" }}
                            className="mx-1 md:mx-2"
                          />
                          {granularFormatDate(document.creation)}{" "}
                        </span>
                        <div className="absolute bottom-2 right-2 md:bottom-4 h-8 py-2">
                          <div className="flex">
                            {viewableFileTypeIcon.includes(
                              thumbnail(document.fileName).type
                            ) &&
                              (thumbnail(document.fileName).type === "audio" ||
                              thumbnail(document.fileName).type === "video" ? (
                                <span>
                                  {loadingPreviews[document.id] ? (
                                    <Spinner className=" h-4 w-4 md:h-6 md:w-6" />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faPlay}
                                      // size="lg"
                                      style={{ color: "#b03a2e" }}
                                      className=" w-4 h-4 md:w-6 md:h-6 cursor-pointer"
                                      onClick={() => generateURL(document)}
                                    />
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {loadingPreviews[document.id] ? (
                                    <Spinner className=" h-4 w-4 md:h-6 md:w-6" />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      //size="lg"
                                      style={{ color: "#007BFF" }}
                                      className="w-4 h-4 mx-0.5 md:mx-1 md:w-6 md:h-6 cursor-pointer"
                                      onClick={() => generateURL(document)}
                                    />
                                  )}{" "}
                                </span>
                              ))}
                            <FontAwesomeIcon
                              icon={faDownload}
                              //size="lg"
                              style={{ color: "#28a745" }}
                              className="w-4 h-4  mx-1 md:mx-2 md:w-6 md:h-6 cursor-pointer "
                              onClick={() => handleDownload(document)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="w-full p-6 md:px-8 items-center">
          <div className="w-48 mx-auto mt-6 py-3">
            <Link
              to="/publications"
              className="text-white bg-green-700 hover:bg-green-500 focus:ring-2 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-700 dark:hover:bg-green-500 dark:focus:ring-green-300"
            >
              Découvrir plus
              <svg
                aria-hidden="true"
                className="w-4 h-4 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
      {docPreview !== null &&
        (docPreview.fileName.split(".").pop().toLowerCase() === "pdf" ? (
          <PDFModal isVisible={showDialog} onClose={handleCloseDialog}>
            <HandlePreview previewURL={previewURL} docPreview={docPreview} />
          </PDFModal>
        ) : (
          <ModalPreview isVisible={showDialog} onClose={handleCloseDialog}>
            <HandlePreview previewURL={previewURL} docPreview={docPreview} />
          </ModalPreview>
        ))}
    </>
  );
}

export default Recent;
