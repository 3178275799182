import { Carousel, Spinner } from "@material-tailwind/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBinaryContent } from "../../../utils/document/GetBinaryContent";
import { objectURL, typeMIME } from "../../../utils/document/ObjectURL";
import mapping from "../../../utils/mapping";
import Queries from "../../../utils/queries";

const fallbackSlides = [
  { src: "/images/slide/1.jpg", alt: "Slide 1" },
  { src: "/images/slide/2.jpg", alt: "Slide 2" },
  { src: "/images/slide/3.jpg", alt: "Slide 3" },
  { src: "/images/slide/4.jpg", alt: "Slide 4" },
  { src: "/images/slide/6.jpg", alt: "Slide 6" },
];

function Caroussel() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [carouselItems, setCarouselItems] = useState([]);
  const { t } = useTranslation();

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await Queries.documents(
        process.env.REACT_APP_FOLDER_ADMIN_ID
      );
      const documentsFetched = mapping.document(response.data);
      setDocuments(documentsFetched);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setDocuments([]);
    } finally {
    }
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const getUrl = useCallback(async (document) => {
    const typemime = typeMIME(document);
    if (typemime.startsWith("image")) {
      try {
        const binaryContent = await getBinaryContent(document);
        return await objectURL(document, binaryContent);
      } catch (error) {
        console.error("Error getting binary content:", error);
        return null;
      } finally {
      }
    }
    return null;
  }, []);

  const createCarouselItem = useCallback(
    (src, alt, index) => (
      <div key={index} className="relative h-full w-full">
        <img src={src} alt={alt} className="h-full w-full object-cover" />
        <div className="absolute inset-0 rounded-xl grid h-full w-full place-items-center bg-black/10">
          <div className="w-3/4 text-center md:w-2/4 p-1">
            {/* Add any additional content here if needed */}
          </div>
        </div>
      </div>
    ),
    []
  );

  useEffect(() => {
    const renderCarouselItems = async () => {
      let items = [];

      if (documents.length > 0) {
        const serverItems = await Promise.all(
          documents.map(async (document, index) => {
            const url = await getUrl(document);
            return url
              ? createCarouselItem(url, `Slide ${index + 1}`, index)
              : null;
          })
        );
        items = serverItems.filter(Boolean);
      }

      if (items.length === 0) {
        items = fallbackSlides.map((slide, index) =>
          createCarouselItem(slide.src, slide.alt, index)
        );
      }

      setCarouselItems(items);
      setLoading(false);
    };

    renderCarouselItems();
  }, [documents, getUrl, createCarouselItem]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[37rem]">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  return (
    <section className="border-current h-[37rem] mt-16">
      {carouselItems.length > 0 ? (
        <Carousel autoplay={true} autoplayDelay={5000} loop={true}>
          {carouselItems}
        </Carousel>
      ) : (
        <div className="text-center">No images available</div>
      )}
    </section>
  );
}

export default Caroussel;

// import { Carousel, Spinner } from "@material-tailwind/react";
// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { getBinaryContent } from "../../../utils/document/GetBinaryContent";
// import { objectURL, typeMIME } from "../../../utils/document/ObjectURL";
// import mapping from "../../../utils/mapping";
// import Queries from "../../../utils/queries";

// function Caroussel() {
//   const [error, setError] = useState("");
//   const [documents, setdocuments] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [carouselItems, setCarouselItems] = useState([]);
//   const { t } = useTranslation();

//   useEffect(() => {
//     const docs = async () => {
//       try {
//         setLoading(true);
//         const response = await Queries.documents(
//           process.env.REACT_APP_FOLDER_ADMIN_ID
//         );
//         const documentsFetched = mapping.document(response.data);
//         setdocuments(documentsFetched);
//       } catch (error) {
//         //can't throw the exception here and an handle it's not necessary
//         // console.error(
//         //   error.message
//         // );
//         // Gérer l'erreur de manière appropriée, par exemple :
//         setError(
//           "Impossible de charger les documents du Carrousel. Veuillez réessayer plus tard."
//         );
//       } finally {
//         setLoading(false);
//       }
//     };
//     docs();
//   }, []);

//   const getUrl = async (document) => {
//     const typemime = typeMIME(document);
//     if (typemime.startsWith("image")) {
//       const binaryContent = await getBinaryContent(document);
//       let url = await objectURL(document, binaryContent);
//       return url;
//     }

//     return null;
//   };

//   const renderCarouselItems = async () => {
//     const items = [];

//     for (let index = 0; index < documents.length; index++) {
//       const document = documents[index];
//       const url = await getUrl(document);
//       if (url) {
//         items.push(
//           <div key={index} className="relative h-full w-full">
//             <img src={url} alt="" className="h-full w-full object-cover" />
//             <div className="absolute inset-0 rounded-xl grid h-full w-full place-items-center bg-black/10">
//               <div className="w-3/4 text-center md:w-2/4 p-1">
//                 {/* render for caroussel here */}
//               </div>
//             </div>
//           </div>
//         );

//         setCarouselItems(items.slice());

//         // waiting for get the image.
//         await new Promise((resolve) => {
//           const img = new Image();
//           img.onload = resolve;
//           img.src = url;
//         });
//       }
//     }
//   };

//   useEffect(() => {
//     const updateCarouselItems = async () => {
//       await renderCarouselItems();
//     };
//     updateCarouselItems();
//   }, [documents]);
//   return (
//     <section className="border-current h-[37rem] mt-16">
//       {loading ? (
//         <div className="flex items-center justify-center h-full">
//           <Spinner className="h-8 w-8" />
//         </div>
//       ) : error ? (
//         <Carousel>
//           <div className="relative h-full w-full">
//             <img
//               src="/images/slide/1.jpg"
//               alt=""
//               className="h-full w-full object-cover"
//             />
//             {/* <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75"> */}
//             <div className="absolute inset-0 rounded-xl grid h-full w-full place-items-center bg-black/10">
//               <div className="w-3/4 text-center md:w-2/4 p-1">
//                 {/* <Typography
//                   variant="h1"
//                   color="white"
//                   className="mb-4 text-3xl md:text-4xl lg:text-5xl"
//                 >
//                   Lorem ipsum
//                 </Typography>
//                 <Typography
//                   variant="lead"
//                   color="white"
//                   className="mb-12 opacity-80"
//                 >
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
//                   Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
//                 </Typography> */}
//                 {/* <div className="flex justify-center gap-2">
//                   <Button size="lg" color="white">
//                     Consulter
//                   </Button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//           <div className="relative h-full w-full">
//             <img
//               src="/images/slide/2.jpg"
//               alt=""
//               className="h-full w-full object-cover"
//             />
//             <div className="absolute inset-0 grid h-full w-full items-center">
//               <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
//                 {/* <Typography
//                   variant="h1"
//                   color="white"
//                   className="mb-4 text-3xl md:text-4xl lg:text-5xl"
//                 >
//                   Lorem ipsum
//                 </Typography>
//                 <Typography
//                   variant="lead"
//                   color="white"
//                   className="mb-12 opacity-80"
//                 >
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
//                   Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
//                 </Typography> */}
//                 {/* <div className="flex gap-2">
//                   <Button size="lg" color="white">
//                     Consulter
//                   </Button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//           <div className="relative h-full w-full">
//             <img
//               src="/images/slide/3.jpg"
//               alt=""
//               className="h-full w-full object-cover"
//             />
//             <div className="absolute inset-0 grid h-full w-full items-end">
//               <div className="w-3/4 pl-12 pb-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
//                 {/* <Typography
//                   variant="h1"
//                   color="white"
//                   className="mb-4 text-3xl md:text-4xl lg:text-5xl"
//                 >
//                   Lorem ipsum
//                 </Typography>
//                 <Typography
//                   variant="lead"
//                   color="white"
//                   className="mb-12 opacity-80"
//                 >
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
//                   Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
//                 </Typography> */}
//                 {/* <div className="flex gap-2">
//                   <Button size="lg" color="white">
//                     Consulter
//                   </Button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//           <div className="relative h-full w-full">
//             <img
//               src="/images/slide/4.jpg"
//               alt=""
//               className="h-full w-full object-cover"
//             />
//             <div className="absolute inset-0 grid h-full w-full place-items-center">
//               <div className="w-3/4 text-center md:w-2/4">
//                 {/* <Typography
//                   variant="h1"
//                   color="white"
//                   className="mb-4 text-3xl md:text-4xl lg:text-5xl"
//                 >
//                   Lorem ipsum
//                 </Typography>
//                 <Typography
//                   variant="lead"
//                   color="white"
//                   className="mb-12 opacity-80"
//                 >
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
//                   Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
//                 </Typography> */}
//                 {/* <div className="flex justify-center gap-2">
//                   <Button size="lg" color="white">
//                     Consulter
//                   </Button>
//                 </div> */}
//               </div>
//             </div>
//           </div>

//           <div className="relative h-full w-full">
//             <img
//               src="/images/slide/6.jpg"
//               alt=""
//               className="h-full w-full object-cover"
//             />
//             <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/10">
//               <div className="w-3/4 text-center md:w-2/4">
//                 {/* <Typography
//                   variant="h1"
//                   color="white"
//                   className="mb-4 text-3xl md:text-4xl lg:text-5xl"
//                 >
//                   Lorem ipsum
//                 </Typography>
//                 <Typography
//                   variant="lead"
//                   color="white"
//                   className="mb-12 opacity-80"
//                 >
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
//                   Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
//                 </Typography> */}
//                 {/* <div className="flex justify-center gap-2">
//                   <Button size="lg" color="white">
//                     Consulter
//                   </Button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         </Carousel>
//       ) : (
//         <Carousel>{carouselItems}</Carousel>
//       )}
//     </section>
//   );
// }

// export default Caroussel;
