import React, { useState } from 'react';

function Card() {
  const [expanded, setExpanded] = useState(false);

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`h-${expanded ? 'auto' : '52rem'} md:h-${expanded ? '96rem' : '48rem'} lg:h-${expanded ? '50rem' : '36rem'} bg-gray-100 dark:bg-gray-800`} id="section-sist">  
      <div className="w-full p-4 md:p-8 uppercase tracking-wide font-semibold text-md md:text-md md:text-base lg:text-xl xl:text-2xl 2xl:text-3xl text-center font-semibold antialiased font-custom">
        Système d’Information Scientifique et Technique
      </div>

      <div className="w-10/12 mx-auto">
        <div className={`xl:flex ${expanded ? 'flex-col' : 'mt-5 md:mt-2 lg:mt-10'}`}>
          {expanded ? (
            <>
              <div className="p-2 md:p-12 w-full">
                <div className="uppercase tracking-wide text-lg text-green-700 font-bold">Description</div>
                <p className="mt-2 font-custom text-gray-700 dark:text-gray-400 text-justify">
                  Le Ministère de l’Agriculture, des Ressources Naturelles et du Développement Rural (MARNDR) a lancé en 2018 le Programme d’Innovation Technologique 
                  en Agriculture et Agroforesterie (PITAG) en partenariat avec le Programme Mondial pour l’Agriculture et la Sécurité Alimentaire (GAFSP), 
                  le Fonds International pour le Développement Agricole (FIDA) et la Banque Interaméricaine (BID). L'objectif principal de ce projet est d'accroître la productivité
                  des petits agriculteurs dans certaines régions clés d'Haïti, notamment les départements du Nord, du Nord-est, de l'Artibonite, 
                  du Sud et de la Grande Anse.
                </p>
                <p className="mt-2 font-custom text-gray-700 dark:text-gray-400 text-justify">
                  Au cœur de ce programme se trouve le projet de mise en place d’un Système d’information scientifique et technique (SIST). 
                  Il s'agit d'une banque de données scientifiques et techniques conçue pour promouvoir la recherche agricole en Haïti et faciliter
                  l'accès à l'information pour la communauté scientifique et le grand public. Sur cette plateforme, les chercheurs et le grand
                  public peuvent consulter librement des publications scientifiques, des rapports de recherche, des études techniques, ainsi que
                  des ressources pédagogiques numériques telles que des syllabus, des livres, des vidéos et des audios. Cette vaste collection 
                  de documents favorise la diffusion et l'échange des connaissances, contribuant ainsi à l'avancement de la recherche agricole 
                  et au développement rural en Haïti.
                </p>
                <p className="mt-2 font-custom text-gray-700 dark:text-gray-400 text-justify">
                  Le SIST ne se limite pas à la diffusion des connaissances. Il offre également des fonctionnalités permettant la collaboration et
                  la publication de documents scientifiques et techniques par des chercheurs nationaux et internationaux. Les chercheurs peuvent 
                  facilement charger, éditer, valider et publier leurs travaux, tout en suivant des flux de travail adaptés. Cette approche 
                  collaborative et multidisciplinaire favorise l'émergence de nouvelles idées et de solutions novatrices pour relever les défis 
                  agricoles en Haïti.
                </p>
                <p className="mt-2 font-custom text-gray-700 dark:text-gray-400 text-justify">
                  Grâce à l'implémentation du SIST, la recherche agricole en Haïti est stimulée, ouvrant ainsi la voie à des avancées 
                  significatives dans le domaine de l'agriculture et contribuant au développement durable du pays. Parcourez nos publications 
                  dès maintenant!
                </p>
                <button
                  type="button"
                  onClick={toggleDescription}
                  className="mt-8 text-white bg-green-700 hover:bg-green-500 focus:ring-2 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-700 dark:hover:bg-green-500 dark:focus:ring-green-300"
                >
                  Voir moins
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 ml-2 -mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* Image */}
            </>
          ) : (
            <>
              <div className="md:shrink-0">
                <img className={`h-48 w-11/12 pb-8 rounded-xl object-cover lg:w-11/12 ${expanded ? 'md:h-[22rem] lg:h-96' : 'md:h-[22rem] lg:h-[22rem]'}`} src="/images/desc.jpg" alt="" />
              </div>
              <div className="p-2 md:pl-12 w-full">
                <div className="uppercase tracking-wide text-lg text-green-700 font-semibold">Description</div>
                <p className="mt-2 font-custom text-gray-700 dark:text-gray-400 text-justify">
                  Le Ministère de l’Agriculture, des Ressources Naturelles et du Développement Rural (MARNDR) a lancé en 2018 le Programme d’Innovation Technologique 
                  en Agriculture et Agroforesterie (PITAG) en partenariat avec le Programme Mondial pour l’Agriculture et la Sécurité Alimentaire (GAFSP), 
                  le Fonds International pour le Développement Agricole (FIDA) et la Banque Interaméricaine (BID). L'objectif principal de ce projet est d'accroître la productivité
                  des petits agriculteurs dans certaines régions clés d'Haïti, notamment les départements du Nord, du Nord-est, de l'Artibonite, 
                  du Sud et de la Grande Anse.
                </p>
                <button
                  type="button"
                  onClick={toggleDescription}
                  className="mt-8 text-white bg-green-700 hover:bg-green-500 focus:ring-2 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-700 dark:hover:bg-green-500 dark:focus:ring-green-300"
                >
                  Découvrir plus
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 ml-2 -mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Card;
