import React, { useContext, useEffect } from "react";
import { Link, useLocation, useRouteError } from "react-router-dom";
import PageContent from "../../components/Error/PageContent";
import GlobalContext from "../../store/context";

const ErrorPage = () => {
  const error = useRouteError();
  const { setIsSearchLoading } = useContext(GlobalContext);
  const location = useLocation();

  let title = "Une erreur est survenue";
  let message =
    "Nos équipes travaillent activement pour résoudre ce problème dans les plus brefs délais.";
  // console.log("ERRRor", error);

  useEffect(() => {
    if (error) {
      setIsSearchLoading(false);
    }
  }, [error, setIsSearchLoading]);

  if (error.status === 500) {
    try {
      const errorData = JSON.parse(error.response.data);
      message = errorData.message || message;
    } catch (e) {
      console.error("Erreur lors du parsing de l'erreur:", e);
    }
  } else if (error.status === 404) {
    title = "Ressource non trouvée!";
    message = "Impossible de trouver la ressource indiquée.";
  } else if (error.message) {
    // Pour les erreurs personnalisées provenant de nos appels API
    message = error.message;
  }

  return (
    <PageContent title={title}>
      <div>{message}</div>
      {location.pathname !== "/" && (
        <Link
          to="/"
          className="pr-3 hover:underline uppercase tracking-wide text-sm text-indigo-500 font-semibold"
        >
          Retour à la page d'accueil
        </Link>
      )}
    </PageContent>
  );
};

export default ErrorPage;
