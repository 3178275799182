import React from "react";

function PageContent({ title, children }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-center">
        <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {title}
            </h5>
          </div>
          <div className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {children}
          </div>
        </div>

        {/* <h1>{title}</h1>
        {children} */}
      </div>
    </div>
  );
}

export default PageContent;
