import Queries from "../../queries";
export const getBinaryContent = async (document) => {
  try {
    const response = await Queries.getBinaryDocument(document.id);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des documents récents:",
      error.message
    );
    // Gérer l'erreur de manière appropriée, par exemple :
    // setError("Impossible de charger les documents récents. Veuillez réessayer plus tard.");
  } finally {
    // setIsLoading(false);
  }
};
