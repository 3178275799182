import React, { useState } from "react";
import Card from "../../components/Home/Card";
import Caroussel from "../../components/Home/Caroussel";
import Recent from "../../components/Home/Recent";
import { useRecentDocuments } from "../../hooks/useRecentDocuments";
import ImprovedSpinner from "../../components/ImprovedSpinner";
import { useEnrichedDocuments } from "../../hooks/useEnrichedDocuments";

const Home = () => {
  const [detailsViewed, setDetailsViewed] = useState(null);
  const maxHits = parseInt(
    process.env.REACT_APP_MAXHITS_LASTS_PUBLICATIONS,
    10
  );

  const {
    data: recentDocs,
    isLoading: isLoadingDocs,
    error: docsError,
  } = useRecentDocuments(process.env.REACT_APP_FOLDER_ID, maxHits);

  const {
    data: enrichedDocuments,
    isLoading: isLoadingCategories,
    error: categoriesError,
  } = useEnrichedDocuments(recentDocs);

  const onDetailsViewedHandler = (detailsViewed) => {
    setDetailsViewed(detailsViewed);
  };

  const isLoading = isLoadingDocs || isLoadingCategories;
  const error = docsError || categoriesError;

  const renderRecentSection = () => {
    if (isLoading) {
      return <ImprovedSpinner />;
      // return (
      //   <div className="w-full flex justify-center items-center py-8">
      //     {/* <Spinner className="h-8 w-8" /> */}
      //     Chargement des dernieres publications ...
      //   </div>
      // );
    }

    if (error) {
      return (
        <div className="w-full text-center py-4 text-red-500">
          Les documents récents n'ont pas pu être récupérés. Veuillez réessayer
          plus tard.
        </div>
      );
    }

    return enrichedDocuments?.length > 0 ? (
      <Recent
        documents={enrichedDocuments}
        onDetailsView={onDetailsViewedHandler}
      />
    ) : null;
  };

  return (
    <div className="bg-gray-100">
      <Caroussel />
      <Card />
      {renderRecentSection()}
    </div>
  );
};

export default Home;
