import { Spinner } from "@material-tailwind/react";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../store/context";
import { getBinaryContent } from "../../utils/document/GetBinaryContent";
import HandlePreview from "../../utils/document/HandlePreview";
import { objectURL } from "../../utils/document/ObjectURL";
import mapping from "../../utils/mapping";
import Queries from "../../utils/queries";
import Document from "../Document";
import DocumentDetails from "../DocumentDetails";

export default function Documents({
  subfolderId,
  onFetchedDocuments,
  filteredDocuments,
}) {
  const [filterResults, setFilterResults] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [detailsViewed, setDetailsViewed] = useState(null);
  const [urlDetails, setUrlDetails] = useState(null);
  const { isDetailsView, setIsDetailsView } = useContext(GlobalContext);

  const onIsDetailsViewHandler = (isDetailsView) => {
    setIsDetailsView(isDetailsView);
  };

  const onDetailsViewedHandler = (detailsViewed) => {
    setDetailsViewed(detailsViewed);
  };

  const handleReturn = () => {
    setIsDetailsView(false);
    setDetailsViewed(null);
    URL.revokeObjectURL(urlDetails);
    setUrlDetails(null);
  };

  useEffect(() => {
    const getUrl = async () => {
      try {
        let binaryContent = await getBinaryContent(detailsViewed);
        let url = await objectURL(detailsViewed, binaryContent);
        return url;
      } catch (error) {
        // Gérer l'erreur ici si nécessaire
      }
    };

    const fetchUrl = async () => {
      let url = await getUrl();
      setUrlDetails(url);
    };

    if (detailsViewed) {
      fetchUrl();
    }
  }, [detailsViewed]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setIsLoading(true);
        const response = await Queries.documents(subfolderId);
        const documents = mapping.document(response.data);

        onFetchedDocuments(documents);
        // setIsLoading(false);
        // setUrlDetails(null);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des documents :",
          error.message
        );
        // Gérer l'erreur de manière appropriée, par exemple :
        // setError("Impossible de charger les documents récents. Veuillez réessayer plus tard.");
      } finally {
        setIsLoading(false);
        setUrlDetails(null);
      }
    };

    if (subfolderId !== null) {
      setIsLoading(true);
      fetchDocuments();
      setIsDetailsView(false);
      setFilterResults(null);
    } else {
      setIsLoading(false);
      // setFilterResults('Veuillez choisir une catégorie pour la liste de documents associés!');
      setFilterResults(
        "Sélectionner une catégorie pour commencer à consulter ses documents."
      );
    }
  }, [subfolderId]);

  return (
    <div className="">
      {isDetailsView ? (
        <div className="w-full">
          <div className="w-full flex justify-between items-center pr-2">
            <div></div>
            <div className="bg-gradient-to-r from-green-50 to-blue-50 p-1 rounded-lg">
              <span
                onClick={handleReturn}
                className="text-md text-orange dark:text-blue-500 hover:underline cursor-pointer sm:text-xl md:text-2xl font-bold font-custom"
              >
                Retour à la liste
              </span>
            </div>
          </div>
          {detailsViewed && (
            <DocumentDetails docForDetails={detailsViewed}>
              <HandlePreview
                previewURL={urlDetails}
                docPreview={detailsViewed}
              />
            </DocumentDetails>
          )}
        </div>
      ) : (
        <div className="w-11/12 mx-auto">
          <div className="grid grid-cols-2 gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8">
            {isLoading ? (
              <div className="grid md:col-span-1 lg:col-span-3 xl:col-span-4 2xl:col-span-6">
                <div className="flex justify-center items-center min-h-[500px]">
                  <Spinner />
                </div>
              </div>
            ) : filteredDocuments.length !== 0 ? (
              <Document
                filteredDocuments={filteredDocuments}
                onIsDetailsView={onIsDetailsViewHandler}
                onDetailsViewed={onDetailsViewedHandler}
              />
            ) : (
              <div className="grid col-span-full">
                <div className="flex justify-center items-center min-h-[500px]">
                  {filterResults !== null ? (
                    <div className="w-full">
                      {/* <EmptyBox text={filterResults} /> */}
                      <div className="flex items-center justify-center h-48 mb-4 rounded">
                        <p className="w-full p-4 md:p-8 text-orange-400 font-extrabold uppercase tracking-wide text-md md:text-md lg:text-xl xl:text-xl 2xl:text-xl text-center antialiased font-custom">
                          {filterResults}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full">
                      {/* <EmptyBox text="Aucun document à afficher." /> */}
                      <div className="flex items-center justify-center h-48 mb-4 rounded">
                        <p className="w-full p-4 md:p-8 text-orange-400 font-extrabold uppercase tracking-wide text-md md:text-md lg:text-lg xl:text-xl 2xl:text-xl text-center antialiased font-custom">
                          Aucun document à afficher.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
