export const filter = (documents, selectedFormat) => {
  return documents.filter((document) => {
    if (selectedFormat === "Tous") {
      return true;
    } else if (selectedFormat === "Vidéo") {
      return (
        document.fileName.toLowerCase().includes(".mp4") ||
        document.fileName.toLowerCase().includes(".ogg") ||
        document.fileName.toLowerCase().includes(".mkv") ||
        document.fileName.toLowerCase().includes(".avi") ||
        document.fileName.toLowerCase().includes(".flv") ||
        document.fileName.toLowerCase().includes(".web")
      );
    } else if (selectedFormat === "Audio") {
      return (
        document.fileName.toLowerCase().includes(".mp3") ||
        document.fileName.toLowerCase().includes(".wav") ||
        document.fileName.toLowerCase().includes(".flac")
      );
    } else if (selectedFormat === "Image") {
      return (
        document.fileName.toLowerCase().includes(".png") ||
        document.fileName.toLowerCase().includes(".jpeg") ||
        document.fileName.toLowerCase().includes(".jpg") ||
        document.fileName.toLowerCase().includes(".gif") ||
        document.fileName.toLowerCase().includes(".ico") ||
        document.fileName.toLowerCase().includes(".svg")
      );
    } else if (selectedFormat === "Texte") {
      return (
        document.fileName.toLowerCase().includes(".pdf") ||
        document.fileName.toLowerCase().includes(".txt") ||
        document.fileName.toLowerCase().includes(".docx") ||
        document.fileName.toLowerCase().includes(".doc") ||
        document.fileName.toLowerCase().includes(".ppt") ||
        document.fileName.toLowerCase().includes(".pptx") ||
        document.fileName.toLowerCase().includes(".xls") ||
        document.fileName.toLowerCase().includes(".xlsx")
      );
    } else {
      return false;
    }
  });
};

export const paginate = (documents, currentPage, itemsPerPage) => {
  const totalFiltered = documents.length;
  const totalPages = Math.ceil(totalFiltered / itemsPerPage);

  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = currentPage * itemsPerPage;

  if (endIndex > totalFiltered) {
    endIndex = totalFiltered;
  }

  const paginatedDocuments = documents.slice(startIndex, endIndex);

  return { paginatedDocuments, totalFiltered, totalPages };
};

export const filterRecentDocuments = (documents, maxHits) => {
  return (
    documents
      // Convert dates
      .sort((a, b) => {
        const dateA = new Date(a.creation);
        const dateB = new Date(b.creation);
        return dateB - dateA; // desc sort
      })
      // get only maxHits
      .slice(0, parseInt(maxHits))
  );
};
