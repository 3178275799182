import { useQuery } from "@tanstack/react-query";
import { enrichDocumentsWithCategories } from "../utils/queries/utils";

export function useEnrichedDocuments(documents) {
  return useQuery({
    queryKey: ["documentsCategories", documents],
    queryFn: () => enrichDocumentsWithCategories(documents),
    enabled: !!documents,
  });
}
