import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_LD_PATH_API,
  auth: {
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PASSWORD,
  },
});

const handleApiError = (error, functionName) => {
  // console.error(`Error in ${functionName}:`, error.response);

  if (error.code === "ERR_NETWORK" || error.message === "Network Error") {
    throw new Error(
      "Erreur de connexion au serveur. Veuillez vérifier votre connexion internet et l'état du serveur."
    );
  } else if (error.response) {
    // if (error.response.status === 500) {
    //   console.log(
    //     "Une erreur interne s'est produite sur le serveur. Veuillez réessayer ultérieurement."
    //   );
    // }
    throw new Error(`${error.response.data.message}`);
  } else if (error.request) {
    throw new Error("Aucune réponse reçue du serveur");
  } else {
    throw new Error("Erreur dans l'établissement de la demande");
  }
};

const Queries = {
  async folders(folderId) {
    try {
      const response = await axiosInstance.get("/folder/folders", {
        params: { folderId },
      });
      return response;
    } catch (error) {
      handleApiError(error, "folders");
    }
  },

  async documentsSearch(expression, folderId) {
    try {
      const response = await axiosInstance.post(`/document/search/find`, {
        expression,
        folderId,
      });
      return response;
    } catch (error) {
      //   if (error.response && error.response.status === 404 && error.response.data.message === "Aucun document trouvé") {
      //     return []; // Retourner une liste vide pour ce cas spécifique
      // }
      handleApiError(error, "documentsSearch");
    }
  },

  async documents(folderId) {
    try {
      const response = await axiosInstance.get(`/document/documents`, {
        params: { folderId },
      });
      return response;
    } catch (error) {
      handleApiError(error, "documents");
    }
  },

  async getBinaryDocument(docId) {
    try {
      const response = await axiosInstance.get(
        `/document/getBinaryContent/${docId}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      handleApiError(error, "getBinaryDocument");
    }
  },

  async getRecentDocuments(docId) {
    try {
      const response = await axiosInstance.get(`/document/getRecentDocuments`, {
        params: {
          maxHits: process.env.REACT_APP_MAXHITS_LASTS_PUBLICATIONS,
        },
      });
      return response;
    } catch (error) {
      handleApiError(error, "getRecentDocuments");
    }
  },
  async getDocumentsCategory(docId) {
    try {
      const response = await axiosInstance.get(
        `/folder/getDocumentParentFolder`,
        {
          params: { docId },
        }
      );
      return response;
    } catch (error) {
      handleApiError(error, "getDocumentsCategory");
    }
  },
};

export default Queries;
